import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getAnnounces } from "../api";
import { IAnnounce } from "../types";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

export default function Announces() {
  const { isLoading, data } = useQuery<IAnnounce[]>({
    queryKey: ["announces"],
    queryFn: getAnnounces,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pagesToShow = 10;
  const totalPages =
    data && data.length > 0 ? Math.ceil(data.length / itemsPerPage) : 1;

  const currentData = data
    ? data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  // 페이지 번호 표시 계산
  const startPage =
    Math.floor((currentPage - 1) / pagesToShow) * pagesToShow + 1;
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Helmet>
        <title>DPNS | 전자공고</title>
      </Helmet>
      <Box bgColor="gray.50" px={20} py={10}>
        <Heading fontSize="2xl">전자공고</Heading>
      </Box>
      <Box px={20} py={10} maxWidth="container.lg" mx="auto">
        <Text mb={6} color="gray.600" fontWeight={"bold"}>
          전체{" "}
          <Text as="span" color="blue.500">
            {data ? data.length : 0}
          </Text>
          건
        </Text>
        {isLoading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        ) : (
          <Table variant="simple">
            <Thead bg="gray.50">
              <Tr>
                <Th width="70%" textAlign="center" fontSize="md">
                  제목
                </Th>
                <Th width="30%" textAlign="center" fontSize="md">
                  등록일
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data ? (
                data.length === 0 ? (
                  <Tr>
                    <Td>
                      <Text fontSize="md" color="gray.600">
                        게시물이 없습니다.
                      </Text>
                    </Td>
                    <Td></Td>
                  </Tr>
                ) : (
                  currentData.map((announce) => (
                    <Tr key={announce.pk}>
                      <Td>
                        <Link to={`/announces/${announce.pk}`}>
                          <Text
                            fontSize="md"
                            fontWeight="bold"
                            color="teal.600"
                          >
                            {announce.title}
                          </Text>
                        </Link>
                      </Td>
                      <Td textAlign="center">
                        {announce.created_at
                          ? `${new Date(announce.created_at).getFullYear()}.${(
                              "0" +
                              (new Date(announce.created_at).getMonth() + 1)
                            ).slice(-2)}.${(
                              "0" + new Date(announce.created_at).getDate()
                            ).slice(-2)}`
                          : "N/A"}
                      </Td>
                    </Tr>
                  ))
                )
              ) : (
                <Tr>
                  <Td>
                    <Text fontSize="md" color="gray.600">
                      게시물이 없습니다.
                    </Text>
                  </Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}
        <Box mb={6}></Box>
        {/* Pagination Controls */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={6}>
          <IconButton
            icon={<FaAngleDoubleLeft />}
            aria-label="First page"
            onClick={handleFirstPage}
            isDisabled={currentPage === 1 || totalPages === 1}
            mr={2}
          />
          <IconButton
            icon={<FaAngleLeft />}
            aria-label="Previous page"
            onClick={handlePrevPage}
            isDisabled={currentPage === 1 || totalPages === 1}
            mr={2}
          />
          {/* 페이지 번호 표시 */}
          {Array.from({ length: endPage - startPage + 1 }, (_, idx) => (
            <Text
              key={startPage + idx}
              mx={2}
              cursor="pointer"
              fontWeight={currentPage === startPage + idx ? "bold" : "normal"}
              onClick={() => handlePageClick(startPage + idx)}
            >
              {startPage + idx}
            </Text>
          ))}
          <IconButton
            icon={<FaAngleRight />}
            aria-label="Next page"
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages || totalPages === 1}
            ml={2}
          />
          <IconButton
            icon={<FaAngleDoubleRight />}
            aria-label="Last page"
            onClick={handleLastPage}
            isDisabled={currentPage === totalPages || totalPages === 1}
            ml={2}
          />
        </Box>
      </Box>
    </>
  );
}
