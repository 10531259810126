import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import About from "./routes/About";
import Announces from "./routes/Announces";
import AnnounceDetail from "./routes/AnnounceDetail";
import Career from "./routes/Career";
import Admin from "./routes/Admin";
import NotFound from "./routes/NotFound";
import RoomDetail from "./routes/RoomDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "rooms/:roomPk",
        element: <RoomDetail />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "announces",
        element: <Announces />,
      },
      {
        path: "announces/:announcePk",
        element: <AnnounceDetail />,
      },
      {
        path: "career",
        element: <Career />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
]);

export default router;
