import { Box, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

export default function Announces() {
  return (
    <>
      <Helmet>
        <title>DPNS | 회사소개</title>
      </Helmet>
      <Box bgColor="gray.50" px={20} py={10}>
        <Heading fontSize="2xl">회사소개</Heading>
      </Box>
      <Box px={20} py={10} maxWidth="container.lg" mx="auto">
        <Text mb={6} color="gray.600" fontWeight={"bold"}>
          주식회사 디피앤스튜디오
        </Text>
        <Text mb={6} color="gray.600" fontWeight={"bold"}>
          dp&studio Corp.
        </Text>
      </Box>
    </>
  );
}
