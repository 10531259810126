import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>DPNS | Page Not Found</title>
      </Helmet>
      <VStack bg="gray.100" justifyContent={"center"} minH="100vh">
        <Heading>Page not found.</Heading>
        <Text>It seems that you're lost.</Text>
        <Link to="/">
          <Button colorScheme={"red"} variant={"link"}>
            Go home &rarr;
          </Button>
        </Link>
      </VStack>
    </>
  );
}
