import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { IAnnounce } from "../types";
import { getAnnounce, getAnnounces } from "../api";
import { FaArrowLeft, FaDownload } from "react-icons/fa";

export default function AnnounceDetail() {
  const { announcePk } = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useQuery<IAnnounce>({
    queryKey: [`announce`, announcePk],
    queryFn: getAnnounce,
  });
  const { data: allAnnounces } = useQuery<IAnnounce[]>({
    queryKey: ["announces"],
    queryFn: getAnnounces,
  });

  // allAnnounces가 undefined가 아니고, currentIndex도 undefined가 아닐 때만 처리
  let previousAnnounce = null;
  let nextAnnounce = null;
  if (allAnnounces && allAnnounces.length > 0) {
    const currentIndex = allAnnounces.findIndex(
      (announce) => announce.pk === Number(announcePk)
    );

    if (currentIndex > 0) {
      previousAnnounce = allAnnounces[currentIndex - 1];
    }

    if (currentIndex < allAnnounces.length - 1) {
      nextAnnounce = allAnnounces[currentIndex + 1];
    }
  }

  return (
    <>
      <Helmet>
        <title>DPNS | 전자공고</title>
      </Helmet>
      <Box bgColor="gray.50" px={20} py={10}>
        <Heading fontSize="2xl">전자공고</Heading>
      </Box>
      <Box px={20} py={10} maxWidth="container.lg" mx="auto">
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Go back"
          bg="transparent"
          onClick={() => navigate(-1)}
          mb={6}
          mr={4}
        />
        {isLoading ? (
          <Box>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
            />
          </Box>
        ) : (
          <>
            <Text fontSize="2xl" color="teal.600" fontWeight={"bold"} mb={4}>
              {data?.title}
            </Text>
            <Text fontSize="sm" mb={4}>
              {data?.created_at
                ? `${new Date(data.created_at).getFullYear()}.${(
                    "0" +
                    (new Date(data.created_at).getMonth() + 1)
                  ).slice(-2)}.${(
                    "0" + new Date(data.created_at).getDate()
                  ).slice(-2)}`
                : "N/A"}
            </Text>

            {/* Payload를 표시 */}
            <Box
              mb={4}
              py={4}
              px={4}
              borderTop="1px solid"
              borderBottom="1px solid"
              borderTopColor="gray.400"
              borderBottomColor="gray.400"
            >
              <Text whiteSpace="pre-line">{data?.payload}</Text>
            </Box>

            {/* 첨부파일 다운로드 */}
            {data?.file && (
              <>
                <Box px={4} mb={4}>
                  <IconButton
                    as="a"
                    href={data.file}
                    download
                    aria-label="Download file"
                    icon={<FaDownload />}
                    bg="transparent"
                    color="teal.600"
                  />
                </Box>
                <Divider borderColor="gray.300" mb={4} />
              </>
            )}

            {/* 이전 글 */}
            {previousAnnounce && (
              <>
                <Flex mb={4}>
                  <Text color="gray.600" px={4} mr={6}>
                    이전 글
                  </Text>
                  <Text
                    as="a"
                    href={`/announces/${previousAnnounce.pk}`}
                    color="teal.600"
                    fontWeight={"bold"}
                  >
                    {previousAnnounce.title}
                  </Text>
                </Flex>
                <Divider borderColor="gray.300" mb={4} />
              </>
            )}

            {/* 다음 글 */}
            {nextAnnounce && (
              <>
                <Flex mb={4}>
                  <Text color="gray.600" px={4} mr={6}>
                    다음 글
                  </Text>
                  <Text
                    as="a"
                    href={`/announces/${nextAnnounce.pk}`}
                    color="teal.600"
                    fontWeight={"bold"}
                  >
                    {nextAnnounce.title}
                  </Text>
                </Flex>
                <Divider borderColor="gray.300" mb={4} />
              </>
            )}
            <Box mb={10}></Box>
            {/* 목록 버튼 */}
            <Box display="flex" justifyContent="center">
              <Button
                colorScheme="teal"
                width="120px"
                minWidth="80px"
                size="lg"
                onClick={() => navigate("/announces")}
              >
                목록
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
