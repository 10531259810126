import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems="center"
      py={8}
      px={20}
      direction={{
        sm: "column",
        md: "row",
      }}
      spacing={{
        sm: 4,
        md: 8,
      }}
      borderBottomWidth={1}
      wrap="wrap"
    >
      <Box>
        <Link to={"/"}>
          <Text fontSize="2xl" fontWeight="bold" color="gray.800">
            dp
            <Box as="span" color="blue.500">
              &
            </Box>
            studio
          </Text>
        </Link>
      </Box>
      <HStack spacing={10} wrap="wrap">
        <Link to="/about">
          <Button variant="link" color="gray.700">
            회사소개
          </Button>{" "}
        </Link>
        <Link to="/announces">
          <Button variant="link" color="gray.700">
            전자공고
          </Button>{" "}
        </Link>
        <Link to="/career">
          <Button variant="link" color="gray.700">
            채용
          </Button>{" "}
        </Link>
        <Link to="/admin">
          <Button variant="link" color="blue.500" fontWeight="bold">
            관리자
          </Button>
        </Link>
      </HStack>
    </Stack>
  );
}
