import { Box } from "@chakra-ui/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./Header";
import Footer from "./Footer";

export default function Root() {
  return (
    <HelmetProvider>
      <Box minHeight="100vh" bg="white" display="flex" flexDirection="column">
        <Header />
        <Box flex="1">
          <Outlet />
        </Box>
        <Footer />
        <ReactQueryDevtools />
      </Box>
    </HelmetProvider>
  );
}
