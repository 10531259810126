import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>DPNS | Home</title>
      </Helmet>

      {/* Hero Section */}
      <Box
        height="70vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack textAlign="center" spacing={10}>
          <Heading size="2xl" color="gray.800">
            dp&studio Corp.
          </Heading>
          {/* <Text fontSize="lg" color="gray.600">
            We are committed to resolving important issues for many with utmost
            excellence.
          </Text> */}
          <Link to="/about">
            <Button
              colorScheme="teal"
              width="220px"
              minWidth="180px"
              size="lg"
              mx="auto"
            >
              회사소개
            </Button>
          </Link>
        </Stack>
      </Box>
    </>
  );
}
