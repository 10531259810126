import { Box, HStack, Stack, Text, Link } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems="center"
      py={8}
      px={20}
      direction={{
        sm: "column",
        md: "row",
      }}
      spacing={{
        sm: 0,
        md: 0,
      }}
      borderTopWidth={1}
      color="gray.800"
    >
      {/* Copyright Information */}
      <Box>
        <Text fontSize="sm">
          &copy; {new Date().getFullYear()} dp&studio Corp. All Rights Reserved.
        </Text>
      </Box>
    </Stack>
  );
}
